<template>
  <div>
    <b-modal
      v-model="showModal"
      title="Likes Details"
      header-bg-variant="info"
      header-text-variant="default"
      scrollable
      centered
      hide-header-close
      no-close-on-backdrop
    >
      <b-container fluid>
        <div v-for="person in peopleList" :key="person._id" class="mb-3">
          <b-avatar
            size="2rem"
            :src="person.image ? 'data:image/png;base64,' + person.image : ''"
          ></b-avatar>
          <router-link
            class="person-hover ml-2"
            :to="{
              name: 'Author',
              params: { id: person._id },
            }"
            ><span>
              {{ person.name }}
            </span></router-link
          >
        </div>
      </b-container>

      <template #modal-footer>
        <b-button
          v-if="!isLoading"
          size="md"
          variant="outline-primary"
          @click="$emit('closedModal')"
        >
          Close
        </b-button>
        <b-button v-else variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          Loading...
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton, BAvatar, BSpinner } from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BAvatar,
    BSpinner,
  },

  props: {
    peopleList: [],
    isLoading: {
      required: true,
      default: false,
    },
  },

  data() {
    return {
      showModal: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.person-hover {
  color: black;

  &:hover {
    color: black;
  }
}
</style>
